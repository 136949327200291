import React from 'react'

import Section from '../../../shared/section'
import VideoSettingsForm from '../../../../../containers/settings/my-account/account-details/video-settings/video-settings'
import { TESTERS } from '../../../../../lib/tester-classnames'
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags'

function VideoSettings() {
  const showAutoplayTrailer = (useFeatureFlags(
    ['respect_turnOffTrailerAutoplay'],
    false
  )).respect_turnOffTrailerAutoplay

  return (
    showAutoplayTrailer && (
      <Section
        title="Video Settings"
        testerClassName={TESTERS.ACCOUNT.VIDEO_SETTINGS}
      >
        <VideoSettingsForm />
      </Section>
    )
  )
}

export default VideoSettings
